import "./authWrapper.scss";

import Paper from "../paper/Paper";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import TextTitle from "../texts/textTitle/TextTitle";

const AuthWrapper = ({ title, children }) => {
    const { isAuth } = useSelector((state) => state.auth);

    const location = useLocation();

    const redirectPath = location.search.slice(12, location.search.length);

    return (
        <>
            {!isAuth ? (
                <Paper className={"auth-paper"}>
                    <TextTitle className={"auth-paper-title"}>
                        {title}
                    </TextTitle>
                    {children}
                </Paper>
            ) : (
                <Navigate to={redirectPath ? redirectPath : "/home"} />
            )}
        </>
    );
};

export default AuthWrapper;
