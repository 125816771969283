import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isRulesModalOpen: false,
};

const modalsSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        setIsRulesModalOpen: (state, action) => {
            state.isRulesModalOpen = action.payload;
        },
    },
});

const { reducer, actions } = modalsSlice;
export default reducer;
export const { setIsRulesModalOpen } = actions;
