import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    coin: null,
    network: null,
    depositValue: 0,
};

const loadingSlice = createSlice({
    name: "deposit",
    initialState,
    reducers: {
        nextPage: (state) => {
            state.page = state.page + 1;
        },
        prevPage: (state) => {
            state.page = state.page - 1;
        },
        setNetwork: (state, action) => {
            state.network = action.payload;
        },
        setCoin: (state, action) => {
            state.coin = action.payload;
        },
        setDepositValue: (state, action) => {
            console.log(action.payload);
            state.depositValue = action.payload;
        },
    },
});

const { reducer, actions } = loadingSlice;
export const { prevPage, nextPage, setCoin, setNetwork, setDepositValue } =
    actions;
export default reducer;
