import { Link } from "react-router-dom";
import "./mainButton.scss";

const MainButton = ({
    isSelected,
    name,
    color,
    className,
    children,
    type,
    style,
    withoutBg,
    disabled,
    isLink,
    onClick,
    href,
}) => {
    const colorAsClass = color ? `btn-${color}` : "";
    const withoutBgClass = withoutBg ? "btn-withoutBg" : "";
    const classNames = `btn ${isSelected ? "btn-selected" : ""} ${
        className ?? ""
    } ${colorAsClass} ${withoutBgClass}`;

    const Component = isLink ? Link : "button";

    return (
        <Component
            type={type}
            disabled={disabled}
            onClick={onClick}
            name={name}
            style={style}
            to={isLink ? href : ""}
            className={classNames}
        >
            {children}
        </Component>
    );
};

export default MainButton;
