import { ReactComponent as SettingsIcon } from "../../../resources/icons/settingsIcon.svg";
import { ReactComponent as FAQIcon } from "../../../resources/icons/faqIcon.svg";

import faqBgImg from "../../../resources/blocks/faq.webp";
import settingsBgImg from "../../../resources/blocks/settings.webp";

import DepositBlock from "../../depositBlock/DepositBlock";

const fastNavItems = [
    {
        type: "deposit",
        content: <DepositBlock withoutIcon isLinkVariant />,
    },
    {
        type: "link",
        icon: <SettingsIcon />,
        href: "/settings",
        bgImage: settingsBgImg,
        title: "Настройки",
    },
    {
        type: "link",
        icon: <FAQIcon />,
        href: "/settings",
        bgImage: faqBgImg,
        title: "FAQ",
    },
];

export default fastNavItems;
