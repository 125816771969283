import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import InstallsService from "../../services/InstallsService";

const { userAuth } = new InstallsService();

export const fetchAuth = createAsyncThunk(
    "auth/authorization",
    async (loginData) => {
        const response = await userAuth(loginData);
        return response;
    }
);

const initialState = {
    status: "idle",
    isAuth: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setIsAuth: (state, action) => {
            state.isAuth = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAuth.fulfilled, (state, action) => {
                localStorage.setItem("token", action.payload.access_token);
                localStorage.setItem(
                    "refreshToken",
                    action.payload.refresh_token
                );

                state.isAuth = true;
                state.status = "idle";
            })
            .addCase(fetchAuth.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAuth.rejected, (state) => {
                state.status = "error";
            });
    },
});

const { reducer, actions } = authSlice;
export default reducer;
export const { setIsAuth } = actions;
