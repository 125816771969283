import { Link } from "react-router-dom";
import "./iconButton.scss";

const IconButton = ({
    isSelected,
    name,
    color,
    className,
    children,
    type,
    style,
    disabled,
    isLink,
    onClick,
    icon: leftIcon,
    rightIcon,
    href,
}) => {
    const colorAsClass = color ? `icon-btn-${color}` : "";
    const classNames = `icon-btn ${isSelected ? "icon-btn-selected" : ""} ${
        className ?? ""
    } ${colorAsClass}`;

    const Component = isLink ? Link : "button";

    console.log(href, isLink);

    return (
        <Component
            type={type}
            disabled={disabled}
            onClick={onClick}
            name={name}
            style={style}
            to={isLink ? href : ""}
            className={classNames}
        >
            {leftIcon} {children} {rightIcon}
        </Component>
    );
};

export default IconButton;
