import "./spinnerLayout.scss";

import { useEffect } from "react";

import { useSelector } from "react-redux";
import TextSubtitle from "../texts/textSubtitle/TextSubtitle";

const SpinnerLayout = ({ children }) => {
    const { loadingText } = useSelector((state) => state.loading);

    useEffect(() => {
        const body = document.body;

        body.classList.add("no-scroll");

        return () => {
            body.classList.remove("no-scroll");
        };
    }, []);

    return (
        <>
            <div className="loader-bg">
                <div className="loader-wrapper">{children}</div>
            </div>
        </>
    );
};

export default SpinnerLayout;
