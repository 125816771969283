import "./table.scss";

const TableData = ({ style, variant, colSpan, children }) => {
    const rowVariant = variant
        ? `main-table__row-data-${variant}`
        : "main-table__row-data-main";

    return (
        <td className={`${rowVariant}`} colSpan={colSpan} style={style}>
            <div>
                <span>{children}</span>
            </div>
        </td>
    );
};

export default TableData;
