import "./alertModal.scss";

import { useDispatch, useSelector } from "react-redux";

import { closeAlert } from "../../redux/slices/alertSlice";

import MainButton from "../buttons/mainButton/MainButton";
import ModalWindow from "../modalWindow/ModalWindow";
import TextSubtitle from "../texts/textSubtitle/TextSubtitle";

import replaceSpacesWithBr from "../../utils/replaceSpacesWithBr";

const AlertModal = () => {
    const dispatch = useDispatch();

    const { alertData, alertPromiseResolve, isAlertOpen } = useSelector(
        (state) => state.alert
    );

    const handleClose = (id) => {
        dispatch(closeAlert());
        alertPromiseResolve(id);
    };

    const buttonsItems = alertData.buttons.map(({ id, name }) => {
        return (
            <MainButton key={id} onClick={() => handleClose(id)}>
                {name}
            </MainButton>
        );
    });

    return (
        <ModalWindow
            size="big"
            style={{
                padding: "60px 0 30px 0",
            }}
            isOpen={isAlertOpen}
            title={alertData.title}
            handleModalClose={handleClose}
            position="center"
            withBg
        >
            <TextSubtitle
                component="div"
                style={{
                    textAlign: "center",
                }}
                dangerouslySetInnerHTML={{
                    __html: replaceSpacesWithBr(alertData.message),
                }}
            />
            <div className="alert-modal__btns-wrapper">{buttonsItems}</div>
        </ModalWindow>
    );
};

export default AlertModal;
