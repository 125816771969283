import "./menu.scss";

import logo from "../../resources/icons/logo.svg";
import { ReactComponent as MenuBurger } from "../../resources/icons/menuBurger.svg";

import menuList from "./menuList";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
import MobileMenu from "../leftMenu/mobileMenu/MobileMenu";

import { useDispatch } from "react-redux";
import { toggleMenu } from "../../redux/slices/mobileMenuSlice";
import Logo from "../logo/Logo";

const Menu = () => {
    const { isAuth } = useSelector((state) => state.auth);

    const isMobile = useMediaQuery({ maxWidth: 894 });

    const menuItems = menuList.map(({ type, content, href }, i) => {
        if (type === "notifications") {
            return <li key={i}>{content}</li>;
        }
        return (
            <li key={i}>
                <Link to={href}>{content}</Link>
            </li>
        );
    });

    const dispatch = useDispatch();

    return (
        <header className="header">
            <div className="container">
                <div className="header__menu">
                    <div className="header__top">
                        <div
                            className={isMobile && isAuth ? "mobile_menu" : ""}
                        >
                            {isMobile && isAuth ? (
                                <>
                                    <MenuBurger
                                        onClick={() => dispatch(toggleMenu())}
                                        className="menu-button"
                                    />
                                    <MobileMenu />
                                </>
                            ) : (
                                <Link to={"/"}>
                                    <Logo />
                                </Link>
                            )}
                        </div>
                        {isAuth ? (
                            <ul className="header__account-info">
                                {menuItems}
                            </ul>
                        ) : null}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Menu;
