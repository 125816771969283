import "./newsCard.scss";

import { useState } from "react";

import { ReactComponent as Ring } from "../../resources/icons/notifications.svg";

import Paper from "../paper/Paper";
import TimeItem from "../timeItem/TimeItem";
import MainButton from "../buttons/mainButton/MainButton";

import { useMediaQuery } from "react-responsive";

const NewsCard = ({ title, descr, time, isReaded }) => {
    const [isNewsReaded, setIsNewsReaded] = useState(isReaded);

    const isMobile = useMediaQuery({ maxWidth: 768 });

    const buttonText = isNewsReaded ? "Прочитано" : "Прочитать";

    const toggleNewsReaded = () => {
        setIsNewsReaded((isReaded) => !isReaded);
    };

    return (
        <Paper
            displayFlex
            className="news_card"
            style={{
                gridRow: "span 1",
            }}
        >
            <div
                style={{
                    display: "flex",
                    columnGap: "20px",
                    width: "100%",
                    position: "relative",
                }}
            >
                <div className="news_card-main__item-ring-wrapper">
                    <div
                        className={`news_card-main__item-icon ${
                            !isNewsReaded ? " active" : ""
                        }`}
                    >
                        <Ring />
                    </div>
                    <div className="news_card-main__item-text">
                        <h3>{title}</h3>
                        <p>{descr}</p>
                    </div>
                </div>
                <div className="news_card-main__item-info">
                    <TimeItem time={time} />
                    {!isMobile ? (
                        <MainButton
                            onClick={toggleNewsReaded}
                            isSelected={isNewsReaded}
                        >
                            {buttonText}
                        </MainButton>
                    ) : null}
                </div>
            </div>
        </Paper>
    );
};

export default NewsCard;
