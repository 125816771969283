import "./userContacts.scss";

import { useEffect, useState } from "react";

import AuthInput from "../inputs/authInput/AuthInput";
import InputSelect from "../inputs/select/Select";
import Paper from "../paper/Paper";
import TextTitle from "../texts/textTitle/TextTitle";
import InstallsService from "../../services/InstallsService";

import { Formik } from "formik";
import * as Yup from "yup";
import TextSubtitle from "../texts/textSubtitle/TextSubtitle";
import MainButton from "../buttons/mainButton/MainButton";
import toast from "react-hot-toast";

const { getContact, updateContact } = new InstallsService();

const validationSchema = Yup.object().shape({
    contactType: Yup.object().required(
        "Пожалуйста, введите свои контактные данные"
    ),
    contactValue: Yup.string()
        .required("Пожалуйста, введите свои контактные данные")
        .test(
            "is-tg-or-jabber",
            "Введите корректный Telegram или Jabber",
            function (value) {
                const tgPattern = /^@[A-Za-z0-9_]{5,}$/;
                const jabberPattern = /^[^@]+@[^@]+\.[^@]+$/;

                const parent = this.parent.contactType;

                if (parent) {
                    return parent?.value?.toLowerCase() === "jabber"
                        ? jabberPattern.test(value)
                        : tgPattern.test(value);
                } else {
                    return false;
                }
            }
        ),
});

const errorStyle = {
    marginLeft: "12px",
};

const UserContacts = ({ className }) => {
    const [userContact, setUserContact] = useState(null);
    const [status, setStatus] = useState("idle");

    console.log(userContact);

    useEffect(() => {
        setStatus("loading");
        getContact()
            .then((data) => {
                setUserContact(data);
                setStatus("idle");
            })
            .catch(() => setStatus("error"));
    }, []);

    return (
        <Paper className={className ?? ""} color="dark">
            <TextTitle color="gold">Ваши контакты</TextTitle>

            <Formik
                enableReinitialize
                initialValues={{
                    contactType: userContact?.contact_type
                        ? {
                              value: userContact.contact_type,
                              label:
                                  userContact.contact_type[0].toUpperCase() +
                                  userContact.contact_type.slice(
                                      1,
                                      userContact.contact_type.length
                                  ),
                          }
                        : "",
                    contactValue: userContact?.contact ?? "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    const contactData = {
                        contact: values.contactValue,
                        contact_type: values.contactType?.value,
                    };

                    toast.promise(updateContact(contactData), {
                        loading: "Отправка..",
                        error: "Произошла ошибка, при изменении данных",
                        success: "Контакт сохранен успешно",
                    });

                    setSubmitting(false);
                }}
            >
                {({
                    isSubmitting,
                    handleSubmit,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                }) => (
                    <>
                        {console.log(values.contactType)}
                        <div className="settings-contact__wrapper">
                            <InputSelect
                                name="contactType"
                                className="settings-contact__select"
                                placeholder={"Соцсеть"}
                                onChange={(e) =>
                                    setFieldValue("contactType", e)
                                }
                                value={values.contactType}
                                options={[
                                    { value: "jabber", label: "Jabber" },
                                    {
                                        value: "telegram",
                                        label: "Telegram",
                                    },
                                ]}
                            />
                            <AuthInput
                                type="text"
                                name="contactValue"
                                className="settings-contact__input"
                                placeholder="Контакт"
                                value={values.contactValue}
                                isLoading={status === "loading"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                        <TextSubtitle color="error" style={errorStyle}>
                            {touched.contactValue && errors.contactValue}
                        </TextSubtitle>

                        <div className="settings-contact__submit">
                            <MainButton
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                            >
                                Сохранить
                            </MainButton>
                        </div>
                    </>
                )}
            </Formik>
        </Paper>
    );
};

export default UserContacts;
