import "./notificationsButton.scss";
import { ReactComponent as NotificationsIcon } from "../../../resources/icons/notifications.svg";
import { Link } from "react-router-dom";

const isNotificationsActive = false;

const NotificationsButton = () => {
    return (
        <Link
            className={`notifications ${
                isNotificationsActive ? "notifications_active" : ""
            }`}
            to="/"
        >
            <NotificationsIcon />
        </Link>
    );
};

export default NotificationsButton;
