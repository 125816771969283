import "./inputLoadingSpinner.scss";

const InputLoadingSpinner = ({ width, height, style }) => {
    return (
        <>
            <span
                style={{
                    width: width ?? "48px",
                    height: height ?? "48px",
                    ...style,
                }}
                className="input-loader"
            ></span>
        </>
    );
};

export default InputLoadingSpinner;
