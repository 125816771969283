import "./fileUploaderDragDrop.scss";

import { useCallback } from "react";

import { useDropzone } from "react-dropzone";

import { ReactComponent as FileIcon } from "../../../resources/icons/buyPage/fileIcon.svg";

import TextSubtitle from "../../texts/textSubtitle/TextSubtitle";

const FileUploaderDragDrop = ({ handleChange, onBlur, name, valid }) => {
    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles);
        handleChange(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "file/exe": [".exe"],
        },
    });

    const validClass = valid ? "valid" : "";
    const activeClass = !isDragActive ? "active" : "";

    const clazzNames = `drop_zone-text ${validClass} ${activeClass}`;

    return (
        <div {...getRootProps({ className: "drop_zone" })}>
            <input onBlur={onBlur} name={name} {...getInputProps()} />
            <TextSubtitle className={clazzNames}>
                <FileIcon />{" "}
                {valid
                    ? "Файл успешно загружен"
                    : "Пожалуйста, выберите или перетащите сюда файл"}
            </TextSubtitle>
        </div>
    );
};

export default FileUploaderDragDrop;
