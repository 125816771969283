import { useEffect } from "react";

import Menu from "../menu/Menu";

import {
    HashRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

import HomePage from "../pages/homePage/HomePage";
import PrivateRoute from "../privateRoute/PrivateRoute";
import AuthPage from "../pages/authPage/AuthPage";
import RegisterPage from "../pages/registerPage/RegisterPage";
import { useSelector, useDispatch } from "react-redux";
import OrderPage from "../pages/orderPage/OrderPage";
import LoadingSpinner from "../spinners/loadingSpinner/LoadingSpinner";
import { setIsAuth } from "../../redux/slices";
import DepositPage from "../pages/depositPage/DepositPage";
import OrdersPage from "../pages/ordersPage/OrdersPage";
import SettingsPage from "../pages/settingsPage/SettingsPage";

import { Toaster } from "react-hot-toast";
import RulesModal from "../rulesModal/RulesModal";
import AlertModal from "../alertModal/AlertModal";

const App = () => {
    const { isLoading } = useSelector((state) => state.loading);

    const dispatch = useDispatch();

    useEffect(() => {
        // check auth
        if (localStorage.getItem("token")) {
            dispatch(setIsAuth(true));
        }
    }, []);

    return (
        <Router>
            <Toaster
                toastOptions={{
                    className: "toast",
                }}
                position="top-right"
            />
            <AlertModal />
            <RulesModal />
            <Menu />
            {isLoading ? <LoadingSpinner /> : null}
            <Routes>
                <Route path="/login" element={<AuthPage />} />
                <Route path="/register" element={<RegisterPage />} />

                <Route path="/" element={<PrivateRoute />}>
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/buy" element={<OrderPage />} />
                    <Route path="/deposit" element={<DepositPage />} />
                    <Route path="/orders" element={<OrdersPage />} />
                    <Route path="/settings" element={<SettingsPage />} />
                </Route>
            </Routes>
        </Router>
    );
};

export default App;
