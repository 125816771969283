import parse from "html-react-parser";
import { Children, cloneElement, isValidElement } from "react";

export const parseTextSvgToReact = (textElement, styles) => {
    const element = parse(textElement);

    return Children.map(element, (child) => {
        if (child && isValidElement(child)) {
            return cloneElement(child, {
                style: styles,
            });
        }
    });
};
