import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import InstallsService from "../../services/InstallsService";

const { getPromotions } = new InstallsService();

export const fetchPromotions = createAsyncThunk(
    "promotions/fetchPromotions",
    async () => {
        const response = await getPromotions();
        return response;
    }
);

const initialState = {
    status: "idle",
    promotions: [],
};

const promotionsSlice = createSlice({
    name: "promotions",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchPromotions.fulfilled, (state, action) => {
                state.promotions = action.payload;
                state.status = "idle";
            })
            .addCase(fetchPromotions.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchPromotions.rejected, (state) => {
                state.status = "error";
            });
    },
});

const { reducer } = promotionsSlice;
export default reducer;
