import { setIsRulesModalOpen } from "../redux/slices/modalsSlice";
import store from "../redux/store";

export default function useShowRulesModal() {
    const { dispatch } = store;

    return () => {
        dispatch(setIsRulesModalOpen(true));
    };
}
