import { configureStore } from "@reduxjs/toolkit";

import {
    auth,
    news,
    user,
    mobileMenu,
    loading,
    promotions,
    modals,
    deposit,
    alert,
} from "../slices";

const store = configureStore({
    reducer: {
        auth,
        news,
        user,
        mobileMenu,
        loading,
        promotions,
        modals,
        deposit,
        alert,
    },
    devTools: true,
});

export default store;
