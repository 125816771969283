import "./ordersPage.scss";

import { useState } from "react";

import UploadFileButton from "../../buttons/uploadFileButton/UploadFileButton";
import AuthInput from "../../inputs/authInput/AuthInput";
import AuthLabel from "../../labels/authLabel/AuthLabel";
import ModalWindow from "../../modalWindow/ModalWindow";

import { ReactComponent as FileIcon } from "../../../resources/icons/buyPage/fileIcon.svg";
import MainButton from "../../buttons/mainButton/MainButton";
import ModalButtons from "../../modalWindow/ModalButtons";
import TextSubtitle from "../../texts/textSubtitle/TextSubtitle";

import * as Yup from "yup";

import { Formik } from "formik";
import formikOnFileChange from "../../../utils/formikOnFileChange";

import FileUploaderDragDrop from "../../inputs/fileUploaderDragDrop/FileUploaderDragDrop";

import InstallsService from "../../../services/InstallsService";
import toast from "react-hot-toast";

const { changeOrderFile } = new InstallsService();

const initialValues = {
    file: "",
};

const validationSchema = Yup.object({
    file: Yup.string().test("file", "Загрузите файл", function (value) {
        if (this.parent.fileUrl || value) {
            return true;
        }

        return false;
    }),
});

const fileTypes = ["EXE"];

const OrdersEditModal = ({ isOpen, orderId, handleModalClose }) => {
    return (
        <ModalWindow
            position="center"
            isOpen={isOpen}
            handleModalClose={handleModalClose}
            title="Изменение заказа"
            withBg
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    if (orderId) {
                        const sendData = {
                            file: values.file,
                            order_id: orderId,
                        };

                        const promise = changeOrderFile(sendData);

                        toast.promise(promise, {
                            loading: "Изменение файла..",
                            error: "Произошла ошибка при изменении файла",
                            success: "Файл успешно изменён",
                        });
                    }

                    handleModalClose();
                    setSubmitting(false);
                }}
            >
                {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    setFieldValue,
                    errors,
                    setFieldTouched,
                    touched,
                }) => (
                    <>
                        <AuthLabel>Сменить файл</AuthLabel>

                        <FileUploaderDragDrop
                            name="file"
                            valid={touched.file && !errors.file}
                            handleChange={(file) => {
                                formikOnFileChange(file, setFieldValue, "file");
                                setFieldTouched("file", true);
                            }}
                        />

                        {errors.file && touched.file ? (
                            <TextSubtitle
                                className="order-edit__text-error"
                                color="error"
                            >
                                {errors.file}
                            </TextSubtitle>
                        ) : null}

                        <ModalButtons>
                            <MainButton onClick={handleModalClose}>
                                Отменить
                            </MainButton>
                            <MainButton onClick={handleSubmit}>
                                Сохранить
                            </MainButton>
                        </ModalButtons>
                    </>
                )}
            </Formik>
        </ModalWindow>
    );
};

export default OrdersEditModal;
