import "./promotionCard.scss";

import formatDate from "../../utils/formatDate";
import MainButton from "../buttons/mainButton/MainButton";
import Paper from "../paper/Paper";

import { ReactComponent as HourglassIcon } from "../../resources/icons/hourglassIcon.svg";

import { useMediaQuery } from "react-responsive";
import IconButton from "../buttons/iconButton/IconButton";

const PromotionCard = ({ title, descr, endDate }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <Paper
            style={{
                gridRow: "span 2",
            }}
            className="promotion-card"
            outlined
        >
            <div className="promotion-card-main__item-text">
                <h3>{title}</h3>
                <p>{descr}</p>
            </div>
            <IconButton
                icon={<HourglassIcon />}
                color="gold"
                style={{
                    position: "absolute",
                    bottom: isMobile ? "20px" : "27px",
                    right: isMobile ? "20px" : "47px",
                }}
                isSelected
            >
                До {formatDate(new Date(endDate))}
            </IconButton>
        </Paper>
    );
};

export default PromotionCard;
