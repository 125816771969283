import { Link } from "react-router-dom";
import "./authLink.scss";

const AuthLink = ({ href, color, children }) => {
    const colorAsClass = color ? `auth-link-${color}` : "";

    return (
        <Link className={`auth-link ${colorAsClass}`} to={href}>
            {children}
        </Link>
    );
};

export default AuthLink;
