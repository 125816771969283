import InputLoadingSpinner from "../../spinners/inputLoadingSpinner/InputLoadingSpinner";
import "./authInput.scss";

const AuthInput = ({
    placeholder,
    style,
    disabled,
    onChange,
    onBlur,
    type,
    name,
    isLoading,
    id,
    value,
    className,
    color,
}) => {
    const colorClass = color ? `auth-input__${color}` : "";

    return (
        <div className={`auth-input-wrapper ${className ?? ""}`} style={style}>
            <input
                disabled={disabled}
                className={`auth-input ${colorClass}`}
                placeholder={placeholder}
                onBlur={onBlur}
                name={name}
                type={type}
                id={id}
                value={value}
                onChange={onChange}
            ></input>
            {isLoading ? (
                <InputLoadingSpinner
                    style={{
                        position: "absolute",
                        right: "20px",
                        width: "25px",
                        height: "25px",
                        top: "50%",
                    }}
                />
            ) : null}
        </div>
    );
};

export default AuthInput;
