import "./textSubtitle.scss";

const TextSubtitle = ({
    component = "div",
    style,
    color,
    dangerouslySetInnerHTML,
    className,
    children,
}) => {
    const Component = component;

    const colorAsClass = color ? `subtitle-${color}` : "";
    const allClassNames = `${className ?? ""} ${colorAsClass}`;

    return (
        <Component
            style={style}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            className={`subtitle ${allClassNames}`}
        >
            {children}
        </Component>
    );
};

export default TextSubtitle;
