import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import InstallsService from "../../services/InstallsService";


const {getNews} = new InstallsService();

export const fetchNews = createAsyncThunk(
    'news/fetchNews',
    async () => {
        const response = await getNews();
        return response;
    }
);

const initialState = {
    status: 'idle',
    news: [],
};



const news = createSlice({
    name: 'news',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchNews.fulfilled, (state, action) => {
                state.news = action.payload;
                state.status = 'idle';
            })
            .addCase(fetchNews.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchNews.rejected, (state) => {
                state.status = 'error';
            });
    }
})


const {reducer} = news;
export default reducer;