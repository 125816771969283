import "./authLabel.scss";

const AuthLabel = ({ icon, iconColor, children }) => {
    const iconColorClass = iconColor ? `auth-label-icon__${iconColor}` : "";

    return (
        <div className="auth-label">
            {icon ? (
                <div className={`auth-label-icon ${iconColorClass}`}>
                    {icon}
                </div>
            ) : null}
            {children}
        </div>
    );
};

export default AuthLabel;
