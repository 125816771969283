import instance from "../configs/api.config";

class InstallsService {
    _api = instance;

    userAuth = async (loginData) => {
        const response = await this._api.post(
            "/post/userAuthorization",
            loginData
        );
        return response.data;
    };

    userRegister = async (regData) => {
        const response = await this._api.post(
            "/post/userRegistration",
            regData
        );
        return response.data;
    };

    getNews = async () => {
        const response = await this._api.get("/get/allNews");
        return response.data;
    };

    getOrderHistory = async (page) => {
        const response = await this._api.get(
            `/get/ordersHistory?page=${page}&limit=10`
        );
        return response.data;
    };

    getOrderStatus = async (id) => {
        const response = await this._api.get(`/get/checkStatus/${id}`);
        return response.data;
    };

    getTransactionsHistory = async (page) => {
        const response = await this._api.get(
            `/get/transactionsHistory?page=${page}&limit=10`
        );
        return response.data;
    };

    getTariffs = async () => {
        const response = await this._api.get("/get/tariffs");
        return response.data;
    };

    getBalance = async () => {
        const response = await this._api.get("/get/balance");
        return response.data;
    };

    logOut = async () => {
        const response = await this._api.post("/post/logout");
        return response.data;
    };

    getPromotions = async () => {
        const response = await this._api.get("/get/promotions");
        return response.data;
    };

    getWalletAddress = async (coin, network) => {
        const response = await this._api.get(
            `/get/walletAddress?coin=${coin}${
                network ? `&network=${network}` : ""
            }`
        );
        return response.data;
    };

    balanceTopUp = async (amount, coin, txid, network) => {
        const response = await this._api.post("/post/balanceTopUp", {
            amount,
            coin,
            txid,
            network,
        });
        return response.data;
    };

    uploadFile = async (file) => {
        const response = await this._api.post("/post/uploadFile", file);
        return response.data;
    };

    fetchBuying = async (data) => {
        const response = await this._api.post("/post/buying", data);
        return response.data;
    };

    stopOrder = async (data) => {
        const response = await this._api.put(`/put/stopOrder`, data);
        return response.data;
    };

    cancelOrder = async (data) => {
        const response = await this._api.put(`/put/cancelOrder`, data);
        return response.data;
    };

    getServiceContacts = async () => {
        const response = await this._api.get("/get/serviceContacts");
        return response.data;
    };

    getContact = async () => {
        const response = await this._api.get("/get/contact");
        return response.data;
    };

    updateContact = async (data) => {
        const response = await this._api.put("/put/changeContact", data);
        return response.data;
    };

    getRulesOfUse = async () => {
        const response = await this._api.get("/get/ruleOfUse");
        return response.data;
    };

    startOrder = async (data) => {
        const response = await this._api.put("/put/restartOrder", data);
        return response.data;
    };

    changeOrderFile = async (data) => {
        const response = await this._api.post("/post/replacementFile", data);
        return response.data;
    };

    changePassword = async (data) => {
        const response = await this._api.post(
            "/user/post/changePassword",
            data
        );
        return response.data;
    };
}

export default InstallsService;
