import ModalWindow from "../../modalWindow/ModalWindow";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../../../redux/slices/mobileMenuSlice";
import leftMenuList from "../leftMenuList";
import MenuButton from "../../buttons/menuButton/MenuButton";

const MobileMenu = ({}) => {
    const { isMenuOpen } = useSelector((state) => state.mobileMenu);

    const dispatch = useDispatch();

    const leftMenuItems = leftMenuList.map(({ title, icon, href }, i) => {
        return (
            <MenuButton style={{ marginBottom: "10px" }} key={i} href={href}>
                {icon} {title}
            </MenuButton>
        );
    });

    return (
        <ModalWindow
            withBg
            withoutPadding
            size="small"
            isOpen={isMenuOpen}
            handleModalClose={() => dispatch(toggleMenu())}
            position="bottom left"
            style={{
                bottom: "-310px",
                position: "absolute",
                borderRadius: "2px 20px 20px 20px",
            }}
        >
            {leftMenuItems}
        </ModalWindow>
    );
};

export default MobileMenu;
