import "./buttonGroup.scss";

import { Children } from "react";

const ButtonGroup = ({ children }) => {
    const buttons = Children.map(children, (child) => {
        return (
            <>
                {child}
                <div className="button-group__divider" />
            </>
        );
    });

    return <div className="button-group__wrapper">{buttons}</div>;
};

export default ButtonGroup;
