import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import InstallsService from "../../services/InstallsService";

const { getBalance } = new InstallsService();

export const fetchBalance = createAsyncThunk("user/fetchBalance", async () => {
    const response = await getBalance();
    return response;
});

const initialState = {
    status: "idle",
    balance: 0,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchBalance.fulfilled, (state, action) => {
                state.balance = action.payload;
                state.status = "idle";
            })
            .addCase(fetchBalance.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchBalance.rejected, (state) => {
                state.status = "error";
            });
    },
});

const { reducer } = userSlice;
export default reducer;
