import { ReactComponent as TetherIcon } from "../resources/icons/tether.svg";
import { ReactComponent as BitcoinIcon } from "../resources/icons/bitcoin.svg";

const depositMethods = [
    {
        id: 1,
        coin: "USDT",
        network: "trc20",
        method: "USDT (trc20)",
        icon: <TetherIcon />,
    },
    {
        id: 2,
        coin: "USDT",
        network: "erc20",
        method: "USDT (erc20)",
        icon: <TetherIcon />,
    },
    {
        id: 3,
        coin: "BTC",
        method: "BTC",
        icon: <BitcoinIcon />,
    },
];

export default depositMethods;
