import "./rulesModal.scss";

import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { setIsRulesModalOpen } from "../../redux/slices/modalsSlice";

import MainButton from "../buttons/mainButton/MainButton";
import ModalWindow from "../modalWindow/ModalWindow";
import TextSubtitle from "../texts/textSubtitle/TextSubtitle";

import InstallsService from "../../services/InstallsService";
import replaceSpacesWithBr from "../../utils/replaceSpacesWithBr";
import InputLoadingSpinner from "../spinners/inputLoadingSpinner/InputLoadingSpinner";

const { getRulesOfUse } = new InstallsService();

const RulesModal = () => {
    const [rules, setRules] = useState("");
    const [status, setStatus] = useState("idle");

    const { isRulesModalOpen } = useSelector((state) => state.modals);

    const dispatch = useDispatch();

    useEffect(() => {
        setStatus("loading");
        getRulesOfUse()
            .then((rules) => {
                setRules(replaceSpacesWithBr(rules));
                setStatus("idle");
            })
            .catch(() => setStatus("error"));
    }, []);

    const handleModalClose = () => {
        dispatch(setIsRulesModalOpen(false));
    };

    const content =
        status === "idle" ? (
            <TextSubtitle dangerouslySetInnerHTML={{ __html: rules }} />
        ) : null;

    const loading =
        status === "loading" ? (
            <InputLoadingSpinner
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                }}
            />
        ) : null;

    return (
        <ModalWindow
            size="big"
            style={{
                padding: "60px 0 30px 0",
            }}
            isOpen={isRulesModalOpen}
            title="Правила сервиса"
            handleModalClose={handleModalClose}
            position="center"
            withBg
        >
            {content}
            {loading}
            <div className="rules-modal__btns-wrapper">
                <MainButton onClick={handleModalClose}>Закрыть</MainButton>
            </div>
        </ModalWindow>
    );
};

export default RulesModal;
