import "./inputSelect.scss";

import Select from "react-select";

const InputSelect = ({
    options,
    className,
    onChange,
    value,
    name,
    placeholder,
}) => {
    return (
        <Select
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            isSearchable={false}
            value={value}
            className={`input-select-container ${className ?? ""}`}
            classNamePrefix="input-select"
            options={options}
        />
    );
};

export default InputSelect;
