import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isMenuOpen: false,
};

const mobileMenuSlice = createSlice({
    name: "moblieMenu",
    initialState,
    reducers: {
        toggleMenu: (state) => {
            state.isMenuOpen = !state.isMenuOpen;
        },
    },
});

const { reducer, actions } = mobileMenuSlice;
export const { toggleMenu } = actions;
export default reducer;
