import { setLoading, setLoadingText } from "../redux/slices/loadingSlice";
import store from "../redux/store";

export default function useShowLoading() {
    const { dispatch } = store;

    return (isLoading, loadingText) => {
        dispatch(setLoading(isLoading));
        if (loadingText) {
            dispatch(setLoadingText(loadingText));
        } else {
            dispatch(setLoadingText(""));
        }
    };
}
