import { showAlert } from "../redux/slices/alertSlice";
import store from "../redux/store";

const { dispatch, getState } = store;

export default function useShowAlert() {
    return (alertData) => {
        dispatch(showAlert(alertData));
        return getState().alert.alertPromise;
    };
}
