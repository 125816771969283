import toast from "react-hot-toast";

import InstallsService from "../services/InstallsService";

const { uploadFile } = new InstallsService();

const toastCheckFileMsg = {
    loading: "Загрузка файла",
    success: "Файл успешно загружен",
    error: "Ошибка загрузки файла",
};

const formikOnFileChange = (file, setFieldValue, fieldName) => {
    const formData = new FormData();

    formData.append("file", file);

    const uploadFilePromice = uploadFile(formData).then((data) => {
        setFieldValue(fieldName, data.file_url);
    });

    toast.promise(uploadFilePromice, toastCheckFileMsg, {
        style: {
            minWidth: "250px",
        },
    });
};

export default formikOnFileChange;
