import axios from "axios";

class BinanceService {
    _apiUrl = "https://api.binance.com/api";
    _apiVersion = "v3";

    _instance = axios.create({
        baseURL: `${this._apiUrl}/${this._apiVersion}`,
    });

    getTicker = async (symbol) => {
        const response = await this._instance.get(
            `/ticker/price?symbol=${symbol}`
        );
        return response.data;
    };
}

export default BinanceService;
