import "./backButton.scss";
import MainButton from "../mainButton/MainButton";
import { ReactComponent as BackIcon } from "../../../resources/icons/backIcon.svg";

const BackButton = ({ onClick, className }) => {
    const classes = `back-btn ${className ?? ""}`;

    return (
        <MainButton onClick={onClick} className={classes} isSelected>
            <BackIcon />
        </MainButton>
    );
};

export default BackButton;
