import "./mainInput.scss";

const MainInput = ({
    placeholder,
    style,
    onChange,
    onBlur,
    type,
    name,
    id,
    value,
    className,
}) => {
    return (
        <input
            className={`main-input ${className ?? ""}`}
            placeholder={placeholder}
            onBlur={onBlur}
            name={name}
            type={type}
            id={id}
            value={value}
            style={style}
            onChange={onChange}
        />
    );
};

export default MainInput;
