import "./linkButton.scss";

const LinkButton = ({ color, onClick, children }) => {
    const colorAsClass = color ? `link-btn__${color}` : "";

    return (
        <div onClick={onClick} className={`link-btn ${colorAsClass}`}>
            {children}
        </div>
    );
};

export default LinkButton;
