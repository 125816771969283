import { Link } from "react-router-dom";
import "./blockItem.scss";

const BlockItem = ({
    title,
    icon,
    bgImage,
    bgImageStyle,
    iconColor,
    isActive,
    withoutMobileVersion,
    className,
    isLink,
    href,
    children,
}) => {
    const withoutMobileVersionClass = !withoutMobileVersion
        ? "main__nav-item-mobile"
        : "";

    const colorClass = iconColor ? `main__nav-item-icon-${iconColor}` : "";

    const Component = isLink ? Link : "div";

    return (
        <Component
            to={isLink ? href : ""}
            className={`main__nav-item ${withoutMobileVersionClass} ${
                isActive ? "selected" : ""
            } ${className ?? ""} ${colorClass}`}
        >
            <div className="main__nav-item-title">
                {icon}
                <h6>{title}</h6>
            </div>
            {children}
            <img style={bgImageStyle} src={bgImage} alt="" />
        </Component>
    );
};

export default BlockItem;
