import "./loadingSpinner.scss";

import { useSelector } from "react-redux";

import SpinnerLayout from "../SpinnerLayout";
import TextSubtitle from "../../texts/textSubtitle/TextSubtitle";

const LoadingSpinner = () => {
    const { loadingText } = useSelector((state) => state.loading);

    return (
        <SpinnerLayout>
            <div className="main-loader-wrapper">
                <span className="main-loader" />
                <TextSubtitle
                    style={{
                        textAlign: "center",
                    }}
                >
                    {loadingText}
                </TextSubtitle>
            </div>
        </SpinnerLayout>
    );
};

export default LoadingSpinner;
