import { ReactComponent as WalletIcon } from "../../resources/icons/walletIcon.svg";
import { ReactComponent as HistoryIcon } from "../../resources/icons/historyIcon.svg";
import { ReactComponent as HomeIcon } from "../../resources/icons/homeIcon.svg";
import { ReactComponent as BuyIcon } from "../../resources/icons/buyIcon.svg";
import { ReactComponent as SettingsIcon } from "../../resources/icons/settingsIcon.svg";

const leftMenuList = [
    {
        icon: <HomeIcon />,
        title: "Главная",
        href: "/home",
    },
    {
        icon: <BuyIcon />,
        title: "Купить",
        href: "/buy",
    },
    {
        icon: <WalletIcon />,
        title: "Баланс",
        href: "/deposit",
    },
    {
        icon: <HistoryIcon />,
        title: "Заказы",
        href: "/orders",
    },
    {
        icon: <SettingsIcon />,
        title: "Настройки",
        href: "/settings",
    },
    // {
    //     icon: <WalletIcon />,
    //     title: "nothome",
    //     href: "/d",
    // },
    // {
    //     icon: <WalletIcon />,
    //     title: "nothome",
    //     href: "/d",
    // },
];

export default leftMenuList;
