import "./homePage.scss";

import { Fragment, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { fetchNews } from "../../../redux/slices/newsSlice";

import { useMediaQuery } from "react-responsive";

import BlockItem from "../../blockItem/BlockItem";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import fastNavItems from "./fastNavItems";
import PromotionCard from "../../promotionCard/PromotionCard";
import NewsCard from "../../newsCard/NewsCard";
import useShowLoading from "../../../hooks/useShowLoading";
import { fetchPromotions } from "../../../redux/slices/promotionsSlice";

const HomePage = () => {
    const { news, status: newsStatus } = useSelector((state) => state.news);
    const { promotions, status: promotionsStatus } = useSelector(
        (state) => state.promotions
    );

    const isMobile = useMediaQuery({ query: "(max-width: 1268px)" });

    const dispatch = useDispatch();

    const setLoading = useShowLoading();

    useEffect(() => {
        setLoading(newsStatus === "loading");
    }, [newsStatus]);

    useEffect(() => {
        dispatch(fetchNews());
        dispatch(fetchPromotions());
    }, []);

    const filtredItems = fastNavItems.filter(({ type }) => {
        if (type === "deposit" && isMobile) {
            return false;
        }

        return true;
    });

    const blockItems = filtredItems.map(
        ({ type, content, icon, bgImage, title, href }, i) => {
            if (type === "deposit") {
                return <Fragment key={i}>{content}</Fragment>;
            }

            return (
                <BlockItem
                    iconColor="dark"
                    href={href}
                    key={i}
                    isLink
                    icon={icon}
                    bgImage={bgImage}
                    title={title}
                />
            );
        }
    );

    const promotionItems =
        promotionsStatus === "idle"
            ? promotions.map(({ id, title, body, end_date }) => {
                  return (
                      <PromotionCard
                          key={id}
                          title={title}
                          descr={body}
                          endDate={end_date}
                      />
                  );
              })
            : null;

    const newsItems =
        newsStatus === "idle"
            ? news.map(({ id, title, content, is_readed: isReaded, date }) => {
                  return (
                      <NewsCard
                          key={id}
                          title={title}
                          descr={content}
                          isReaded={isReaded}
                          time={date}
                      />
                  );
              })
            : null;

    return (
        <>
            <MainLayout>
                <div className="nav_items">{blockItems}</div>

                {promotionItems}
                {newsItems}
            </MainLayout>
        </>
    );
};

export default HomePage;
