import "./settingsBlockItem.scss";

import BlockItem from "../blockItem/BlockItem";

const SettingsBlockItem = ({ bgImage, title, bgImageStyle, children }) => {
    return (
        <BlockItem
            withoutMobileVersion
            bgImage={bgImage}
            title={title}
            bgImageStyle={bgImageStyle}
            isActive
            className="settings__block-item"
        >
            {children}
        </BlockItem>
    );
};

export default SettingsBlockItem;
