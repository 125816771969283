import Balance from "../balance/Balance";
import NotificationsButton from "../buttons/notificationsButton/NotificationsButton";
import { ReactComponent as ProfileIcon } from "../../resources/icons/profileIcon.svg";

const menuList = [
    {
        type: "balance",
        content: <Balance />,
        href: "/deposit",
    },
    {
        type: "notifications",
        content: <NotificationsButton />,
        href: "/",
    },
    {
        type: "profile",
        content: <ProfileIcon color="#333" />,
        href: "/settings",
    },
];

export default menuList;
