import "./changePasswordForm.scss";

import { Formik } from "formik";

import AuthLabel from "../labels/authLabel/AuthLabel";
import AuthInput from "../inputs/authInput/AuthInput";
import MainButton from "../buttons/mainButton/MainButton";
import TextSubtitle from "../texts/textSubtitle/TextSubtitle";

import * as Yup from "yup";

import ModalWindow from "../modalWindow/ModalWindow";

import InstallsService from "../../services/InstallsService";

import toast from "react-hot-toast";

const initialValues = {
    // oldPassword: "",
    newPassword: "",
    newPasswordCheck: "",
};

const validationSchema = Yup.object().shape({
    // oldPassword: Yup.string().required("Пожалуйста, введите ваш старый"),
    newPassword: Yup.string()
        .min(8, "Пароль должен содержать минимум 8 символов")
        .matches(/[a-z]/, "Пароль должен содержать хотя бы одну строчную букву")
        .matches(
            /[A-Z]/,
            "Пароль должен содержать хотя бы одну заглавную букву"
        )
        .matches(/[0-9]/, "Пароль должен содержать хотя бы одну цифру")
        .matches(
            /[^A-Za-z0-9]/,
            "Пароль должен содержать хотя бы один спецсимвол"
        )
        .required("Пожалуйста, введите пароль"),
    newPasswordCheck: Yup.string()
        .required("Повторите пароль")
        .oneOf([Yup.ref("newPassword"), null], "Пароли должны совпадать"),
});

const errorStyle = {
    marginLeft: "12px",
};

const { changePassword } = new InstallsService();

const ChangePasswordForm = ({ isOpen, handleClose }) => {
    return (
        <ModalWindow
            style={{
                padding: "60px 0",
            }}
            bgColor="dark"
            withBg
            title="Сброс пароля"
            isOpen={isOpen}
            handleModalClose={handleClose}
            position="center"
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    const passData = {
                        password: values.newPassword,
                    };

                    const promise = changePassword(passData);

                    toast.promise(promise, {
                        loading: "Изменение пароля..",
                        error: "Произошла ошибка при изменении пароля",
                        success: "Пароль успешно изменён",
                    });

                    handleClose();
                    setSubmitting(false);
                }}
            >
                {({
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {/* <AuthLabel>Старый пароль</AuthLabel>
                        <AuthInput
                            onBlur={handleBlur}
                            name="oldPassword"
                            type="password"
                            value={values.oldPassword}
                            onChange={handleChange}
                            placeholder="Старый пароль"
                        />
                        <TextSubtitle style={errorStyle} color="error">
                            {touched.oldPassword && errors.oldPassword}
                        </TextSubtitle> */}

                        <AuthLabel>Новый пароль</AuthLabel>
                        <AuthInput
                            name="newPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                            type="password"
                            placeholder="Новый пароль"
                        />
                        <TextSubtitle color="error" style={errorStyle}>
                            {touched.newPassword && errors.newPassword}
                        </TextSubtitle>

                        <AuthLabel>Повторите новый пароль</AuthLabel>
                        <AuthInput
                            name="newPasswordCheck"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPasswordCheck}
                            type="password"
                            placeholder="Повторите новый пароль"
                        />
                        <TextSubtitle color="error" style={errorStyle}>
                            {touched.newPasswordCheck &&
                                errors.newPasswordCheck}
                        </TextSubtitle>

                        <div className="changepass-buttons__wrapper">
                            <MainButton
                                onClick={handleClose}
                                isSelected
                                withoutBg
                            >
                                Отмена
                            </MainButton>
                            <MainButton disabled={isSubmitting} type="submit">
                                Сохранить
                            </MainButton>
                        </div>
                    </form>
                )}
            </Formik>
        </ModalWindow>
    );
};

export default ChangePasswordForm;
