import "./paper.scss";

const Paper = ({
    outlined,
    style,
    className,
    color,
    displayFlex,
    children,
}) => {
    const colorAsClass = color ? `main__item-${color}` : "";
    const displayFlexClass = displayFlex ? "main__item-flex" : "";

    return (
        <div
            style={style}
            className={`main__item ${
                outlined ? "outlined" : ""
            } ${colorAsClass} ${className ?? ""} ${displayFlexClass}`}
        >
            {children}
        </div>
    );
};

export default Paper;
