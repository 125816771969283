import "./walletAddressModal.scss";

import { useCallback, useEffect, useState } from "react";

import AuthInput from "../inputs/authInput/AuthInput";
import AuthLabel from "../labels/authLabel/AuthLabel";
import ModalWindow from "../modalWindow/ModalWindow";

import InstallsService from "../../services/InstallsService";
import convertDollarsToBtc from "../../utils/convertDollarsToBtc";
import TextSubtitle from "../texts/textSubtitle/TextSubtitle";
import depositMethods from "../../configs/depositMethods.config";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../buttons/backButton/BackButton";
import {
    nextPage,
    prevPage,
    setCoin,
    setDepositValue,
    setNetwork,
} from "../../redux/slices/depositSlice";
import MainButton from "../buttons/mainButton/MainButton";
import LinkButton from "../buttons/linkButton/LinkButton";
import useShowLoading from "../../hooks/useShowLoading";

const { getWalletAddress, balanceTopUp } = new InstallsService();

// -------------------------------- pages ---------------------------------

const GetWalletAddressView = () => {
    const { coin, network, depositValue } = useSelector(
        (state) => state.deposit
    );

    const [walletAddress, setWalletAddress] = useState("");
    const [convertedValue, setConvertedValue] = useState("");
    const [txId, setTxId] = useState("");

    const [convertStatus, setConvertStatus] = useState("idle");
    const [walletAddressStatus, setWalletAddressStatus] = useState("idle");
    const [orderStatus, setOrderStatus] = useState("idle");

    useEffect(() => {
        setWalletAddressStatus("loading");
        getWalletAddress(coin?.toLowerCase(), network?.toLowerCase())
            .then((data) => {
                setWalletAddress(data.wallet);
                setWalletAddressStatus("idle");
            })
            .catch(() => {
                setWalletAddressStatus("error");
            });
    }, []);

    const getConvertedValue = async () => {
        if (coin === "BTC") {
            try {
                const convertedValue = await convertDollarsToBtc(
                    depositValue,
                    coin
                );
                setConvertedValue(convertedValue);
            } catch {
                setConvertStatus("error");
                setConvertedValue(0);
            }
        } else {
            setConvertedValue(depositValue);
        }

        setConvertStatus("idle");
    };

    useEffect(() => {
        setConvertStatus("loading");
        getConvertedValue();
    }, []);

    const showLoading = useShowLoading();

    useEffect(() => {
        showLoading(orderStatus === "loading");
    }, [orderStatus]);

    const onTransactionConfirm = useCallback(() => {
        setOrderStatus("loading");
        balanceTopUp(depositValue, coin, txId, network)
            .then(() => {
                setOrderStatus("idle");
            })
            .catch(() => {
                setOrderStatus("error");
            });
    }, [coin, network, depositValue, txId]);

    const handleChangeTxId = useCallback((e) => {
        setTxId(e.target.value);
    }, []);

    const walletAddressLoading = walletAddressStatus === "loading";
    const convertLoading = convertStatus === "loading";

    return (
        <>
            <AuthLabel>{coin} адрес</AuthLabel>
            <AuthInput
                isLoading={walletAddressLoading}
                disabled
                value={walletAddress}
                placeholder={`${coin} адрес`}
            />

            <AuthLabel>Итого необходимо отправить ({coin})</AuthLabel>
            <AuthInput
                isLoading={convertLoading}
                disabled
                value={convertedValue}
                placeholder="Сумма"
            />

            <AuthLabel>Введите ID транзакции</AuthLabel>
            <AuthInput
                value={txId}
                onChange={handleChangeTxId}
                placeholder="Айди"
            />
            <div className="wallet-address__confirm-wrapper">
                <MainButton onClick={onTransactionConfirm}>Оплатил!</MainButton>
            </div>
        </>
    );
};

const ChooseDepositMethodView = () => {
    const dispatch = useDispatch();

    const depositMethodsItems = depositMethods.map(
        ({ method, icon, id, coin, network }) => (
            <div
                key={id}
                onClick={() => {
                    dispatch(setNetwork(network));
                    dispatch(setCoin(coin));

                    dispatch(nextPage());
                }}
                className="choose-wallet__item"
            >
                <TextSubtitle className="choose-wallet__item-title">
                    {icon} {method}
                </TextSubtitle>
            </div>
        )
    );

    return <>{depositMethodsItems}</>;
};

// -----------------------------------------------------------------

const pages = [<ChooseDepositMethodView />, <GetWalletAddressView />];

const WalletAddressModal = ({ isOpen, handleClose, depositValue }) => {
    const { page } = useSelector((state) => state.deposit);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isOpen) return;

        dispatch(setDepositValue(+depositValue));
    }, [isOpen]);

    return (
        <ModalWindow
            style={{
                padding: "60px 0",
            }}
            bgColor="dark"
            withBg
            title="Пополнение баланса"
            isOpen={isOpen}
            handleModalClose={handleClose}
            position="center"
        >
            {page >= 1 ? (
                <BackButton
                    className="deposit__back-button"
                    onClick={() => dispatch(prevPage())}
                />
            ) : null}
            {pages[page]}
        </ModalWindow>
    );
};

export default WalletAddressModal;
