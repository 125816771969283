import { useState } from "react";

import Balance from "../balance/Balance";
import MainButton from "../buttons/mainButton/MainButton";
import MainInput from "../inputs/mainInput/MainInput";
import WalletAddressModal from "../walletAddressModal/WalletAddressModal";

const MobileDepositBlock = () => {
    const [depositValue, setDepositValue] = useState("");
    const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);

    const toggleDepositModal = () => {
        setIsDepositModalOpen((isOpen) => !isOpen);
    };

    const onChangeDepositValue = (e) => {
        if (!isNaN(+e.target.value)) {
            setDepositValue(e.target.value);
        }
    };

    const onDepositConfirm = () => {
        if (!depositValue) return;

        toggleDepositModal();
    };

    return (
        <>
            <Balance size="big" fullWidth withoutIcon />
            <MainInput
                style={{ width: "100%", marginTop: "10px" }}
                value={depositValue}
                placeholder="Введите сумму пополнения"
                onChange={onChangeDepositValue}
            />
            <MainButton
                style={{
                    display: "block",
                    margin: "10px auto",
                }}
                onClick={onDepositConfirm}
            >
                Пополнить
            </MainButton>
            <WalletAddressModal
                depositValue={depositValue}
                isOpen={isDepositModalOpen}
                handleClose={toggleDepositModal}
            />
        </>
    );
};

export default MobileDepositBlock;
