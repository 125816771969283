import "./modalWindow.scss";

import TextTitle from "../texts/textTitle/TextTitle";

const ModalWindow = ({
    title,
    size,
    position,
    withoutPadding,
    withBg,
    isOpen,
    bgColor,
    handleModalClose,
    style,
    children,
}) => {
    const isBg = withBg;

    const modalSize = size ? `modal-${size}` : "";
    const bgColorClass = bgColor ? `modal-${bgColor}` : "";
    const modalPosition = position
        ? position
              .split(" ")
              .map((position) => {
                  return `modal-${position}`;
              })
              .join(" ")
        : "";
    const withoutPaddingClass = withoutPadding ? "modal-without-padding" : "";

    return (
        <>
            {isOpen ? (
                <>
                    {isBg ? (
                        <div
                            className={`modal-bg`}
                            onClick={handleModalClose}
                        />
                    ) : null}
                    <div
                        style={style}
                        className={`modal ${bgColorClass} ${modalSize} ${modalPosition} ${withoutPaddingClass}`}
                    >
                        <div className="modal-content">
                            <TextTitle className="modal-title" component="h2">
                                {title}
                            </TextTitle>
                            {children}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default ModalWindow;
