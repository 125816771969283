import "./textTitle.scss";

const TextTitle = ({
    component = "div",
    style,
    className,
    color,
    children,
}) => {
    const Component = component;

    const colorAsClass = color ? `title-${color}` : "";

    return (
        <Component
            style={style}
            className={`title ${colorAsClass} ${className ?? ""}`}
        >
            {children}
        </Component>
    );
};

export default TextTitle;
