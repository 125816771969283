const declinationMonths = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Август",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
];

const formatDate = (date) => {
    const month = declinationMonths[date.getMonth()].toLowerCase();
    const day = date.getDate();

    return `${day} ${month}`;
};

export default formatDate;
