import "./depositBlock.scss";

import { useState } from "react";

import depositBlockConfig from "../../configs/depositBlock.config";

import BlockItem from "../blockItem/BlockItem";
import MainButton from "../buttons/mainButton/MainButton";
import Balance from "../balance/Balance";
import MainInput from "../inputs/mainInput/MainInput";
import WalletAddressModal from "../walletAddressModal/WalletAddressModal";

const DepositBlock = ({
    justifyContent,
    withoutIcon,
    withInput,
    isLinkVariant,
    withoutMobileVersion,
}) => {
    const [depositValue, setDepositValue] = useState("");
    const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);

    const toggleDepositModal = () => {
        setIsDepositModalOpen((isOpen) => !isOpen);
    };

    const wrapperClassName = `main__nav-item-btns ${
        justifyContent ? `main__nav-item-btns-${justifyContent}` : ""
    }`;

    const onChangeDepositValue = (e) => {
        if (isLinkVariant) return;

        if (!isNaN(+e.target.value)) {
            setDepositValue(e.target.value);
        }
    };

    const onDepositConfirm = () => {
        if (isLinkVariant || !depositValue) return;

        toggleDepositModal();
    };

    return (
        <>
            <BlockItem
                className="deposit"
                bgImage={depositBlockConfig.bgImage}
                icon={depositBlockConfig.icon}
                title={depositBlockConfig.title}
                withoutMobileVersion={withoutMobileVersion}
                isActive
            >
                <div className={wrapperClassName}>
                    <Balance color="dark" withoutIcon={withoutIcon} />
                    <div className="deposit-button__wrapper">
                        {withInput ? (
                            <MainInput
                                value={depositValue}
                                placeholder="Введите сумму пополнения"
                                onChange={onChangeDepositValue}
                            />
                        ) : null}
                        <MainButton
                            isLink={isLinkVariant}
                            onClick={onDepositConfirm}
                            href={"/deposit"}
                        >
                            Пополнить
                        </MainButton>
                    </div>
                </div>
            </BlockItem>
            <WalletAddressModal
                depositValue={depositValue}
                isOpen={isDepositModalOpen}
                handleClose={toggleDepositModal}
            />
        </>
    );
};

export default DepositBlock;
