import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    loadingText: "",
};

const loadingSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setLoadingText: (state, action) => {
            state.loadingText = action.payload;
        },
    },
});

const { reducer, actions } = loadingSlice;
export const { setLoading, setLoadingText } = actions;
export default reducer;
