import "./orderForm.scss";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import { ReactComponent as ChooseTariffIcon } from "../../../resources/icons/buyPage/chooseTariffIcon.svg";
import { ReactComponent as LinkIcon } from "../../../resources/icons/buyPage/linkIcon.svg";
import { ReactComponent as FileIcon } from "../../../resources/icons/buyPage/fileIcon.svg";
import { ReactComponent as InstallIcon } from "../../../resources/icons/buyPage/desktopInstallIcon.svg";

import Checkbox from "../../inputs/checkbox/Checkbox";
import MainButton from "../../buttons/mainButton/MainButton";
import AuthLink from "../../links/authLink/AuthLink";
import InputSelect from "../../inputs/select/Select";
import AuthLabel from "../../labels/authLabel/AuthLabel";
import Paper from "../../paper/Paper";
import AuthInput from "../../inputs/authInput/AuthInput";
import TextSubtitle from "../../texts/textSubtitle/TextSubtitle";
import UploadFileButton from "../../buttons/uploadFileButton/UploadFileButton";

import useShowLoading from "../../../hooks/useShowLoading";

import InstallsService from "../../../services/InstallsService";

import defaultCurrency from "../../../configs/currency";

import toast from "react-hot-toast";
import formikOnFileChange from "../../../utils/formikOnFileChange";
import FileUploaderDragDrop from "../../inputs/fileUploaderDragDrop/FileUploaderDragDrop";
import LinkButton from "../../buttons/linkButton/LinkButton";
import useShowRulesModal from "../../../hooks/useShowRulesModal";

const initialValues = {
    tariff: "",
    fileUrl: "",
    file: "",
    installsCount: "",
    confirm: true,
};

const errorStyle = {
    marginLeft: "12px",
    marginTop: "10px",
};

const { getTariffs, fetchBuying, getOrderStatus } = new InstallsService();

const OrderForm = () => {
    const [tariffs, setTariffs] = useState([]);
    const [status, setStatus] = useState("idle");

    useEffect(() => {
        setStatus("loading");
        getTariffs()
            .then((data) => {
                setTariffs(data);
                setStatus("idle");
            })
            .catch(() => {
                setStatus("error");
            });
    }, []);

    const view = status === "idle" ? <View tariffs={tariffs} /> : null;

    const showLoading = useShowLoading();

    useEffect(() => {
        showLoading(status === "loading");
    }, [status]);

    return <>{view}</>;
};

const fileCheckedStatus = "successfully";
const fileRejectedStatus = "rejected";
const errorStatus = "error";

const fileCheckStatusMsg = {
    on_check: "Проверка файла",
    successfully: "Заказ успешно оформлен",
    rejected: "Ошибка проверки файла",
    loading: "Создание заказа",
};

const View = ({ tariffs }) => {
    const [minCount, setMinCount] = useState(0);

    const [fileCheckStatus, setFileCheckStatus] = useState("idle");
    const [errorMsg, setErrorMsg] = useState("");

    const showLoading = useShowLoading();
    const showRulesModal = useShowRulesModal();

    const handleRulesModalOpen = () => {
        showRulesModal();
    };

    const navigate = useNavigate();

    useEffect(() => {
        console.log(fileCheckStatus === fileRejectedStatus);

        if (fileCheckStatus === fileCheckedStatus) {
            showLoading(false);
            navigate("/orders");
            toast.success(fileCheckStatusMsg[fileCheckStatus]);
        } else if (
            fileCheckStatus === fileRejectedStatus ||
            fileCheckStatus === errorStatus
        ) {
            console.log("disable loading");
            showLoading(false);
            toast.error(errorMsg);
        } else if (fileCheckStatus !== "idle") {
            showLoading(true, fileCheckStatusMsg[fileCheckStatus]);
        }
    }, [fileCheckStatus]);

    const optionItems = tariffs.map(
        ({ id, title, price_per_install, ...values }) => ({
            value: id,
            label: `${title} - ${price_per_install}${defaultCurrency}/инсталл`,
            pricePerInstall: price_per_install,
            ...values,
        })
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                tariff: Yup.object().required("Выберите тариф"),
                file: Yup.string().required("Пожалуйста загрузите файл"),
                installsCount: Yup.number()
                    .required("Введите количество инсталлов")
                    .test(
                        "minCount installs from tarrifs",
                        `Количество инсталлов не может быть меньше ${minCount}`,
                        function (value) {
                            const tariff = this.parent.tariff;

                            if (value < tariff?.min_count) {
                                return false;
                            }

                            return true;
                        }
                    )
                    .typeError("Введите число"),
                confirm: Yup.boolean().oneOf([true], "Подтвердите согласие"),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setFileCheckStatus("loading");

                const sendData = {
                    tariff_id: values.tariff.value,
                    file: values.file ? values.file : values.fileUrl,
                    count: +values.installsCount,
                };

                fetchBuying(sendData)
                    .then(({ order_id }) => {
                        const orderStatusInterval = setInterval(() => {
                            getOrderStatus(order_id).then(
                                ({ check_status }) => {
                                    setFileCheckStatus(check_status);

                                    if (check_status === fileCheckedStatus) {
                                        clearInterval(orderStatusInterval);
                                    } else if (
                                        check_status === fileRejectedStatus
                                    ) {
                                        setErrorMsg(
                                            "При проверке файла были обнаружены вирусы, средства были возвращены на счёт"
                                        );
                                        clearInterval(orderStatusInterval);
                                    }
                                }
                            );
                        }, 10000);
                    })
                    .catch((e) => {
                        setFileCheckStatus(errorStatus);
                        setErrorMsg(
                            e?.response?.data?.msg ?? "Неизвестная ошибка"
                        );
                    });

                setSubmitting(false);
            }}
        >
            {({
                isSubmitting,
                handleSubmit,
                setFieldValue,
                handleChange,
                handleBlur,
                setFieldTouched,
                values,
                errors,
                touched,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Paper className="order-input__wrapper" color="dark">
                        <AuthLabel
                            iconColor="white"
                            icon={<ChooseTariffIcon />}
                        >
                            Выберите тариф
                        </AuthLabel>

                        <InputSelect
                            placeholder={"Выберите тариф"}
                            value={values.tariff}
                            onChange={(e) => {
                                setMinCount(e.min_count);
                                setFieldValue("tariff", e);
                                console.log(errors.tariff);
                            }}
                            onBlur={handleBlur}
                            name="tariff"
                            options={optionItems}
                        />

                        <TextSubtitle color="error" style={errorStyle}>
                            {touched.tariff && errors.tariff}
                        </TextSubtitle>
                    </Paper>

                    <Paper color="dark" className="order-input__wrapper">
                        <AuthLabel iconColor="white" icon={<LinkIcon />}>
                            Файл
                        </AuthLabel>

                        <FileUploaderDragDrop
                            name="file"
                            valid={touched.file && !errors.file}
                            handleChange={(file) => {
                                formikOnFileChange(file, setFieldValue, "file");
                                setFieldTouched("file", true);
                                console.log(errors.file, touched.file);
                            }}
                        />

                        <TextSubtitle color="error" style={errorStyle}>
                            {touched.file && errors.file}
                        </TextSubtitle>
                    </Paper>

                    <Paper className="order-input__wrapper" color="dark">
                        <AuthLabel iconColor="white" icon={<InstallIcon />}>
                            Выберите количество инсталлов
                        </AuthLabel>

                        <AuthInput
                            className="order-input__installs order-input"
                            name="installsCount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.installsCount}
                            placeholder="Количество инсталлов"
                        />

                        <TextSubtitle color="error" style={errorStyle}>
                            {touched.installsCount && errors.installsCount}
                        </TextSubtitle>
                    </Paper>

                    <div className="order-btns__wrapper">
                        <Checkbox
                            error={touched.confirm && errors.confirm}
                            checked={values.confirm}
                            onChange={(e) => {
                                setFieldValue("confirm", e.target.checked);
                            }}
                            name="confirm"
                        >
                            Я прочитал и принимаю <br />
                            <LinkButton
                                color="gold"
                                onClick={handleRulesModalOpen}
                            >
                                правила сервиса
                            </LinkButton>
                        </Checkbox>
                        <MainButton disabled={isSubmitting} type="submit">
                            Купить
                        </MainButton>
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default OrderForm;
