import "./settingsContacts.scss";

import { useEffect, useState } from "react";

import Paper from "../paper/Paper";
import TextTitle from "../texts/textTitle/TextTitle";
import IconButton from "../buttons/iconButton/IconButton";

import InstallsService from "../../services/InstallsService";
import InputLoadingSpinner from "../spinners/inputLoadingSpinner/InputLoadingSpinner";
import TextSubtitle from "../texts/textSubtitle/TextSubtitle";
import { parseTextSvgToReact } from "../../utils/parseTextSvgToReact";

const { getServiceContacts } = new InstallsService();

const SettingsContacts = ({ className }) => {
    const [contacts, setContacts] = useState([]);
    const [status, setStatus] = useState("idle");

    useEffect(() => {
        setStatus("loading");
        getServiceContacts()
            .then((data) => {
                setContacts(data);
                setStatus("idle");
            })
            .catch(() => {
                setStatus("error");
            });
    }, []);

    const contactItems =
        status === "idle"
            ? contacts.map(({ title, icon, link }) => (
                  <IconButton
                      className="settings-company__contacts-wrapper-item"
                      rightIcon={parseTextSvgToReact(icon)}
                      isLink
                      key={title}
                      href={link}
                  >
                      Мы в {title}
                  </IconButton>
              ))
            : null;

    const noneContacts =
        status === "idle" && contactItems.length <= 0 ? (
            <TextSubtitle
                component="div"
                style={{
                    textAlign: "center",
                    width: "100%",
                }}
            >
                Нет контактов
            </TextSubtitle>
        ) : null;

    const loading =
        status === "loading" ? (
            <InputLoadingSpinner
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "60%",
                    transform: "translate(-50%,-50%)",
                }}
            />
        ) : null;

    return (
        <Paper
            className={`settings-company__contacts ${className ?? ""}`}
            color="dark"
        >
            <TextTitle color="gold">Наши контакты</TextTitle>

            <div className="settings-company__contacts-wrapper">
                {contactItems}
                {loading}
                {noneContacts}
            </div>
        </Paper>
    );
};

export default SettingsContacts;
