import "./ordersPage.scss";

import { useEffect, useState } from "react";

import MainLayout from "../../layouts/mainLayout/MainLayout";
import Table from "../../table/Table";
import TableRow from "../../table/TableRow";
import TableData from "../../table/TableData";
import IconButton from "../../buttons/iconButton/IconButton";
import ButtonGroup from "../../buttons/buttonGroup/ButtonGroup";

import { ReactComponent as TimeIcon } from "../../../resources/icons/timeIcon.svg";
import { ReactComponent as PlayIcon } from "../../../resources/icons/ordersPage/playIcon.svg";
import { ReactComponent as PauseIcon } from "../../../resources/icons/ordersPage/pauseIcon.svg";
import { ReactComponent as CashDebitIcon } from "../../../resources/icons/depositPage/cashDebitIcon.svg";
import { ReactComponent as StopIcon } from "../../../resources/icons/ordersPage/stopIcon.svg";
import { ReactComponent as EditIcon } from "../../../resources/icons/ordersPage/editIcon.svg";
import OrdersEditModal from "./OrdersEditModal";
import InstallsService from "../../../services/InstallsService";
import useShowLoading from "../../../hooks/useShowLoading";
import MainButton from "../../buttons/mainButton/MainButton";
import useShowAlert from "../../../hooks/useShowAlert";
import toast from "react-hot-toast";

const columns = [
    {
        name: "Дата",
        icon: <TimeIcon />,
    },
    {
        name: "Тариф",
        icon: <CashDebitIcon />,
    },
    {
        name: "Количество",
        icon: <TimeIcon />,
    },
    {
        name: "Пролито",
        icon: <TimeIcon />,
    },
    {
        name: "Статус",
        icon: null,
    },
    {
        name: "",
        icon: null,
    },
];

const statusList = {
    running: "В процессе",
    paused: "Приостановлен",
    on_check: "На проверке",
    finished: "Завершен",
    in_queue: "В очереди",
    stopped: "На паузе",
    canceled: "Остановлен",
    rejected: "Файл не прошёл проверку, средства возвращены на счёт",
};

const { getOrderHistory, cancelOrder, stopOrder, startOrder } =
    new InstallsService();

const OrdersPage = () => {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState("idle");
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState();

    const [currOrderId, setCurrOrderId] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const showLoading = useShowLoading();
    const showAlert = useShowAlert();

    const showCancelOrderAlert = () => {
        showAlert({
            title: "Внимание!",
            message: `
                Данный заказ невозможно отменить, для отмены заказа обратитесь к администратору
            `,
            buttons: [
                {
                    id: "Ok",
                    name: "Ок",
                },
            ],
        });
    };

    // const getOrders = () => {
    //     return "getting orders";
    // };

    useEffect(() => {
        // if (isReloading) {

        // }
        setStatus("loading");
        getOrderHistory(page)
            .then((data) => {
                const newOrders = data.orders_history;
                const pagesCount = data.page_count;

                setPagesCount(pagesCount);
                setData((oldOrders) => [...oldOrders, ...newOrders]);
                setStatus("idle");
            })
            .catch(() => {
                setStatus("error");
            });
    }, [page]);

    useEffect(() => {
        showLoading(status === "loading");
    }, [status]);

    const toggleModalOpen = (orderId) => {
        setCurrOrderId(orderId);
        setIsEditModalOpen((isOpen) => !isOpen);
    };

    const onCancelOrder = (id) => {
        const sendData = {
            order_id: id,
        };

        setStatus("loading");
        cancelOrder(sendData)
            .then((data) => {
                setData((data) =>
                    data.map(({ order_id, ...order }) => {
                        if (order_id === id) {
                            return {
                                ...order,
                                order_id,
                                order_status: "stopped",
                            };
                        } else {
                            return { ...order, order_id };
                        }
                    })
                );
                setStatus("idle");
            })
            .catch(() => {
                setStatus("idle");
                showCancelOrderAlert(id);
            });
    };

    const onToggleOrder = (id, isStartOrder) => {
        const sendData = {
            order_id: id,
        };

        let orderPromise;

        setStatus("loading");
        if (isStartOrder) {
            orderPromise = startOrder(sendData).then((data) => {
                setData((data) => {
                    const changedOrders = data.map((order) => {
                        if (order.order_id === id) {
                            return { ...order, order_status: "running" };
                        }

                        return order;
                    });

                    return changedOrders;
                });
            });
        } else {
            orderPromise = stopOrder(sendData).then((data) => {
                setData((data) => {
                    const changedOrders = data.map((order) => {
                        if (order.order_id === id) {
                            return { ...order, order_status: "stopped" };
                        }

                        return order;
                    });

                    return changedOrders;
                });
            });
        }

        setStatus("idle");

        toast.promise(orderPromise, {
            loading: "Загрузка",
            success: isStartOrder
                ? "Заказ запущен успешно"
                : "Заказ остановлен успешно",
            error: "Произошла непредвиденная ошибка",
        });
    };

    const toggleAction = (id, isRunning) => {
        if (isRunning) {
            onToggleOrder(id, false);
        } else {
            onToggleOrder(id, true);
        }
    };

    const items =
        status === "idle" && data.length ? (
            data.map(
                ({
                    date,
                    count,
                    order_status,
                    tariff_name,
                    order_id,
                    finished_count,
                }) => {
                    return (
                        <TableRow size="default" key={order_id}>
                            <TableData>{date}</TableData>
                            <TableData>{tariff_name}</TableData>
                            <TableData>{count}</TableData>
                            <TableData>{finished_count}</TableData>
                            <TableData>{statusList[order_status]}</TableData>
                            <TableData>
                                <ButtonGroup>
                                    <IconButton
                                        onClick={() =>
                                            toggleAction(
                                                order_id,
                                                order_status === "running"
                                            )
                                        }
                                        color="noBg"
                                        icon={
                                            order_status === "running" ? (
                                                <PauseIcon />
                                            ) : (
                                                <PlayIcon />
                                            )
                                        }
                                    />
                                    <IconButton
                                        color="noBg"
                                        icon={<StopIcon color="red" />}
                                        onClick={() => onCancelOrder(order_id)}
                                    />
                                    <IconButton
                                        color="noBg"
                                        onClick={() =>
                                            toggleModalOpen(order_id)
                                        }
                                        icon={<EditIcon />}
                                    />
                                </ButtonGroup>
                            </TableData>
                        </TableRow>
                    );
                }
            )
        ) : (
            <TableRow>
                <TableData colSpan={columns.length} variant="center">
                    История заказов пуста
                </TableData>
            </TableRow>
        );

    const loadMore = () => {
        setPage((page) => page + 1);
    };

    return (
        <MainLayout>
            <Table columns={columns}>{items}</Table>
            {page < pagesCount ? (
                <MainButton onClick={loadMore} className="orders-more__btn">
                    Показать ещё
                </MainButton>
            ) : null}
            <OrdersEditModal
                isOpen={isEditModalOpen}
                handleModalClose={toggleModalOpen}
                orderId={currOrderId}
            />
        </MainLayout>
    );
};

export default OrdersPage;
