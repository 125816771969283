import "./timeItem.scss";

import { ReactComponent as TimeIcon } from "../../resources/icons/timeIcon.svg";

import { useMediaQuery } from "react-responsive";

const TimeItem = ({ time }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <div className="time-item">
            {!isMobile ? <TimeIcon /> : null} <span>{time}</span>
        </div>
    );
};

export default TimeItem;
