import { NavLink } from "react-router-dom";
import "./menuButton.scss";

const MenuButton = ({ href, style, children }) => {
    return (
        <NavLink
            to={href}
            style={style}
            className={({ isActive }) =>
                `main__aside-item ${isActive ? "selected" : null}`
            }
        >
            <h4>{children}</h4>
        </NavLink>
    );
};

export default MenuButton;
