import "./balance.scss";

import { ReactComponent as WalletIcon } from "../../resources/icons/walletIcon.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBalance } from "../../redux/slices/userSlice";
import useShowLoading from "../../hooks/useShowLoading";
import defaultCurrency from "../../configs/currency";

// const balanceAmount = 200;

const Balance = ({ color, withoutIcon, style, fullWidth, size }) => {
    const colorClassName = color ? `balance-${color}` : "";
    const fullWidthClassName = fullWidth ? "balance-fullwidth" : "";
    const sizeClassName = size ? `balance-size__${size}` : "";

    const { balance, status } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchBalance());
    }, []);

    const setLoading = useShowLoading();

    useEffect(() => {
        setLoading(status === "loading");
    }, [status]);

    return (
        <>
            <div
                style={style}
                className={`balance ${colorClassName} ${fullWidthClassName} ${sizeClassName}`}
            >
                {withoutIcon ? null : <WalletIcon />}
                <span>
                    {balance && status === "idle" ? balance : 0}{" "}
                    {defaultCurrency}
                </span>
            </div>
        </>
    );
};

export default Balance;
