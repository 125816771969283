import "./leftMenu.scss";

import MenuButton from "../buttons/menuButton/MenuButton";
import leftMenuList from "./leftMenuList";

const LeftMenu = () => {
    const leftMenuItems = leftMenuList.map(({ title, icon, href }, i) => {
        return (
            <MenuButton key={i} href={href}>
                {icon} {title}
            </MenuButton>
        );
    });

    return (
        <div className="main__aside">
            <div className="main__aside-block">{leftMenuItems}</div>
        </div>
    );
};

export default LeftMenu;
