import "./checkbox.scss";

import { ReactComponent as Checkmark } from "../../../resources/icons/checkbox/checkmark.svg";

const Checkbox = ({ checked, onChange, name, children }) => {
    return (
        <div className="checkbox">
            <div className="checkbox-wrapper">
                <input
                    name={name}
                    type="checkbox"
                    id="cb-main"
                    checked={checked}
                    onChange={onChange}
                    className="checkbox-input"
                />
                <span className="checkbox-checkmark">
                    <Checkmark />
                </span>
            </div>
            <label htmlFor="cb-main">{children}</label>
        </div>
    );
};

export default Checkbox;
