import "./authPage.scss";

import { useEffect } from "react";

import { useLocation } from "react-router";

import AuthWrapper from "../../authWrapper/AuthWrapper";
import AuthLabel from "../../labels/authLabel/AuthLabel";

import { ReactComponent as ProfileIcon } from "../../../resources/icons/profileIcon.svg";
import { ReactComponent as KeyIcon } from "../../../resources/icons/keyIcon.svg";
import AuthInput from "../../inputs/authInput/AuthInput";
import { Formik } from "formik";
import MainButton from "../../buttons/mainButton/MainButton";
import AuthLink from "../../links/authLink/AuthLink";

import * as Yup from "yup";
import TextSubtitle from "../../texts/textSubtitle/TextSubtitle";

import { useDispatch, useSelector } from "react-redux";
import { fetchAuth } from "../../../redux/slices/authSlice";

import useShowLoading from "../../../hooks/useShowLoading";

const initialValues = {
    login: "",
    password: "",
};

const validationSchema = Yup.object().shape({
    login: Yup.string().required("Пожалуйста, введите ваш логин"),
    password: Yup.string().required("Пожалуйста, введите ваш пароль"),
});

const errorStyle = {
    marginLeft: "12px",
};

const AuthPage = () => {
    const { status } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const setLoading = useShowLoading();

    useEffect(() => {
        setLoading(status === "loading");
    }, [status]);

    const location = useLocation();

    const prevLocation = location.search;

    return (
        <>
            <AuthWrapper title="Вход">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, setFieldError }) => {
                        dispatch(fetchAuth(values))
                            .unwrap()
                            .catch(() => {
                                setFieldError(
                                    "password",
                                    "Неверный логин или пароль"
                                );
                            });

                        setSubmitting(false);
                    }}
                >
                    {({
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <AuthLabel icon={<ProfileIcon />}>Логин</AuthLabel>
                            <AuthInput
                                onBlur={handleBlur}
                                name="login"
                                value={values.login}
                                onChange={handleChange}
                                placeholder="Логин"
                            />
                            <TextSubtitle style={errorStyle} color="error">
                                {touched.login && errors.login}
                            </TextSubtitle>

                            <AuthLabel icon={<KeyIcon />}>Пароль</AuthLabel>
                            <AuthInput
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                type="password"
                                placeholder="Пароль"
                            />
                            <TextSubtitle color="error" style={errorStyle}>
                                {touched.password && errors.password}
                            </TextSubtitle>

                            <div className="auth-buttons-wrapper">
                                <MainButton
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Войти
                                </MainButton>
                            </div>
                        </form>
                    )}
                </Formik>
            </AuthWrapper>
            <TextSubtitle
                style={{
                    textAlign: "center",
                    marginTop: "25px",
                    padingBottom: "25px",
                }}
            >
                Нет учётной записи?{" "}
                <AuthLink color="gold" href={"/register" + prevLocation}>
                    Создать аккаунт
                </AuthLink>
            </TextSubtitle>
        </>
    );
};

export default AuthPage;
