import { ReactComponent as WalletIcon } from "../resources/icons/walletIcon.svg";

import walletBgImg from "../resources/blocks/wallet.webp";

const depositBlockConfig = {
    title: "Баланс",
    icon: <WalletIcon />,
    bgImage: walletBgImg,
};

export default depositBlockConfig;
