import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router";

import LoadingSpinner from "../spinners/loadingSpinner/LoadingSpinner";

const PrivateRoute = () => {
    const { status, isAuth } = useSelector((state) => state.auth);

    const prevLocation = useLocation();

    if (isAuth && status === "idle") {
        return (
            <>
                <Outlet />
            </>
        );
    }

    if (status !== "loading" && !isAuth) {
        return (
            <>
                <Navigate to={`/login?redirectTo=${prevLocation.pathname}`} />
            </>
        );
    }

    return <LoadingSpinner />;
};

export default PrivateRoute;
