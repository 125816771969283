import DepositBlock from "../../depositBlock/DepositBlock";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import OrderForm from "./OrderForm";

import { useMediaQuery } from "react-responsive";

const OrderPage = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <MainLayout>
            {!isMobile ? (
                <DepositBlock
                    justifyContent="between"
                    withoutIcon
                    isLinkVariant
                />
            ) : null}
            <OrderForm />
        </MainLayout>
    );
};

export default OrderPage;
