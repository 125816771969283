import "./depositPage.scss";

import { useEffect, useState } from "react";

import DepositBlock from "../../depositBlock/DepositBlock";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import Table from "../../table/Table";
import TableRow from "../../table/TableRow";
import TableData from "../../table/TableData";
import MobileDepositBlock from "../../mobileDepositBlock/MobileDepositBlock";
import MainButton from "../../buttons/mainButton/MainButton";

import { useMediaQuery } from "react-responsive";

import { ReactComponent as TimeIcon } from "../../../resources/icons/timeIcon.svg";
import { ReactComponent as TypeIcon } from "../../../resources/icons/depositPage/arrowUpArrowDownIcon.svg";
import { ReactComponent as CashInflowIcon } from "../../../resources/icons/depositPage/cashInflowIcon.svg";
import { ReactComponent as CashDebitIcon } from "../../../resources/icons/depositPage/cashDebitIcon.svg";

import useShowLoading from "../../../hooks/useShowLoading";

import InstallsService from "../../../services/InstallsService";

const { getTransactionsHistory } = new InstallsService();

const columns = [
    {
        name: "Дата",
        icon: <TimeIcon />,
    },
    {
        name: "Тип",
        icon: <TypeIcon />,
    },
    {
        name: "Сумма",
        icon: null,
    },
    {
        name: "Примечание",
        icon: null,
    },
];

const rowsData = [
    {
        date: "2024-03-02",
        amount: -100,
        type: "Пополнение баланса",
    },
];

const DepositPage = () => {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState("idle");
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState();

    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const showLoading = useShowLoading();

    useEffect(() => {
        setStatus("loading");
        getTransactionsHistory(page)
            .then((data) => {
                const newTransactions = data.transactions_history;
                const pagesCount = data.page_count;

                setPagesCount(pagesCount);
                setData((oldTransactions) => [
                    ...oldTransactions,
                    ...newTransactions,
                ]);
                setStatus("idle");
            })
            .catch(() => {
                setStatus("error");
            });
    }, [page]);

    useEffect(() => {
        showLoading(status === "loading");
    }, [status]);

    const items =
        status === "idle"
            ? data.map(({ date, amount, type }, i) => {
                  const getStatus = (amount) => {
                      if (amount > 0) {
                          return (
                              <>
                                  <CashInflowIcon /> Поступление
                              </>
                          );
                      }

                      return (
                          <>
                              <CashDebitIcon /> Списание
                          </>
                      );
                  };

                  return (
                      <TableRow size="default" key={i}>
                          <TableData>
                              <TimeIcon /> {date}
                          </TableData>
                          <TableData>{getStatus(amount)}</TableData>
                          <TableData>{amount}</TableData>
                          <TableData>{type}</TableData>
                      </TableRow>
                  );
              })
            : null;

    const noneHistory =
        status === "idle" && !data.length ? (
            <TableRow>
                <TableData variant="center" colSpan={columns.length}>
                    История пуста
                </TableData>
            </TableRow>
        ) : null;

    const loadMore = () => {
        setPage((page) => page + 1);
    };

    return (
        <MainLayout>
            {isMobile ? (
                <MobileDepositBlock />
            ) : (
                <DepositBlock
                    justifyContent={"between"}
                    withoutIcon
                    withInput
                    withoutMobileVersion
                />
            )}
            <Table columns={columns}>
                {items}
                {noneHistory}
            </Table>
            {page < pagesCount ? (
                <MainButton
                    onClick={loadMore}
                    className="transactions-more__btn"
                >
                    Показать ещё
                </MainButton>
            ) : null}
        </MainLayout>
    );
};

export default DepositPage;
