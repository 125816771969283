import "./mainLayout.scss";

import { useNavigate } from "react-router";

import BackButton from "../../buttons/backButton/BackButton";
import LeftMenu from "../../leftMenu/LeftMenu";

import { useMediaQuery } from "react-responsive";

const MainLayout = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 894 });

    const navigate = useNavigate();

    return (
        <main>
            <section className="main">
                <div className="container">
                    <div className="main__body">
                        {!isMobile ? <LeftMenu /> : null}

                        <div className="main__block">
                            {!isMobile ? (
                                <BackButton onClick={() => navigate(-1)} />
                            ) : null}
                            <div className="main__content">{children}</div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default MainLayout;
