import "./table.scss";

const Table = ({ columns, children }) => {
    const columnItems = columns
        ? columns.map(({ name, icon }, i) => (
              <th key={i}>
                  <div>
                      {icon} {name}
                  </div>
              </th>
          ))
        : null;

    return (
        <div className="main-table__container">
            <table className="main-table">
                <thead>
                    <tr className="main-table__columns">{columnItems}</tr>
                </thead>
                <tbody>{children}</tbody>
            </table>
        </div>
    );
};

export default Table;
