import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAlertOpen: false,
    alertData: {
        message: "",
        title: "",
        buttons: [],
    },
    alertPromise: null,
    alertPromiseResolve: null,
};

const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        showAlert: (state, action) => {
            state.alertPromise = new Promise((resolve) => {
                state.alertPromiseResolve = resolve;
            });

            state.isAlertOpen = true;
            state.alertData = action.payload;
        },
        closeAlert: (state) => {
            state.isAlertOpen = false;
            state.alertData = {
                message: "",
                title: "",
                buttons: [],
            };
            state.alertPromise = null;
            state.alertPromiseResolve = null;
        },
    },
});

const { reducer, actions } = alertSlice;
export default reducer;
export const { showAlert, closeAlert } = actions;
