import axios from "axios";
import reloadAndClearLocalStorage from "../utils/reloadAndClearLocalStorage";

const _apiUrl = "https://savychk.fvds.ru/installs";

const instance = axios.create({
    baseURL: _apiUrl,
});

instance.interceptors.request.use((config) => {
    if (config.headers.Authorization) return config;

    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
});

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = { ...error.config };

        const errorAuthResponseCheck =
            error?.response &&
            error?.response?.status === 401 &&
            error?.config &&
            error?.response?.data.msg === "Access token has expired";

        originalRequest._isRetry = true;

        if (errorAuthResponseCheck && !error.config._isRetry) {
            try {
                const response = await instance.get("/get/newToken", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "refreshToken"
                        )}`,
                    },
                });
                localStorage.setItem("token", response.data?.access_token);

                return instance(originalRequest);
            } catch (error) {
                throw error;
            }
        } else if (
            errorAuthResponseCheck ||
            error?.response?.data.msg === "Invalid access token"
        ) {
            reloadAndClearLocalStorage();
        }

        throw error;
    }
);

export default instance;
