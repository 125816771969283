import "./registerPage.scss";

import AuthWrapper from "../../authWrapper/AuthWrapper";
import AuthLabel from "../../labels/authLabel/AuthLabel";
import AuthInput from "../../inputs/authInput/AuthInput";
import MainButton from "../../buttons/mainButton/MainButton";
import AuthLink from "../../links/authLink/AuthLink";
import LinkButton from "../../buttons/linkButton/LinkButton";
import TextSubtitle from "../../texts/textSubtitle/TextSubtitle";
import InputSelect from "../../inputs/select/Select";
import Checkbox from "../../inputs/checkbox/Checkbox";

import { useLocation, useNavigate } from "react-router";

import { ReactComponent as ProfileIcon } from "../../../resources/icons/profileIcon.svg";
import { ReactComponent as KeyIcon } from "../../../resources/icons/keyIcon.svg";

import { Formik } from "formik";
import * as Yup from "yup";

import useShowRulesModal from "../../../hooks/useShowRulesModal";

import InstallsService from "../../../services/InstallsService";
import toast from "react-hot-toast";

const initialValues = {
    login: "",
    password: "",
    passwordConfirm: "",
    contactType: "",
    contactValue: "",
    inviteCode: "",
};

const validationSchema = Yup.object().shape({
    login: Yup.string()
        .matches(
            /^[a-zA-Z0-9_]+$/,
            "Логин может содержать только буквы, цифры и символы подчеркивания"
        )
        .required("Пожалуйста, введите ваш логин"),
    password: Yup.string()
        .min(8, "Пароль должен содержать минимум 8 символов")
        .matches(/[a-z]/, "Пароль должен содержать хотя бы одну строчную букву")
        .matches(
            /[A-Z]/,
            "Пароль должен содержать хотя бы одну заглавную букву"
        )
        .matches(/[0-9]/, "Пароль должен содержать хотя бы одну цифру")
        .matches(
            /[^A-Za-z0-9]/,
            "Пароль должен содержать хотя бы один спецсимвол"
        )
        .required("Пароль обязателен к заполнению"),
    passwordConfirm: Yup.string()
        .required("Повторите пароль")
        .oneOf([Yup.ref("password"), null], "Пароли должны совпадать"),
    contactType: Yup.object().required(
        "Пожалуйста, введите свои контактные данные"
    ),
    contactValue: Yup.string()
        .required("Пожалуйста, введите свои контактные данные")
        .test(
            "is-tg-or-jabber",
            "Введите корректный Telegram или Jabber",
            function (value) {
                const tgPattern = /^@[A-Za-z0-9_]{5,}$/;
                const jabberPattern = /^[^@]+@[^@]+\.[^@]+$/;

                return this.parent.contactType.value === "jabber"
                    ? jabberPattern.test(value)
                    : tgPattern.test(value);
            }
        ),
    inviteCode: Yup.string(),
});

const errorStyle = {
    marginLeft: "12px",
};

const { userRegister } = new InstallsService();

const RegisterPage = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const prevLocation = location.search;

    const showRulesModal = useShowRulesModal();

    const handleRulesModalOpen = () => {
        showRulesModal();
    };

    return (
        <>
            <AuthWrapper title="Регистрация">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        const registerData = {
                            login: values.login,
                            password: values.password,
                            contact: values.contactValue,
                            contact_type: values.contactType?.value,
                        };

                        userRegister(registerData)
                            .then(() => {
                                toast.success("Зарегистрирован");
                                navigate("/login");
                            })
                            .catch((e) => {
                                console.log(e);
                            });

                        setSubmitting(false);
                    }}
                >
                    {({
                        isSubmitting,
                        handleSubmit,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <AuthLabel icon={<ProfileIcon />}>Логин</AuthLabel>
                            <AuthInput
                                onBlur={handleBlur}
                                name="login"
                                value={values.login}
                                onChange={handleChange}
                                placeholder="Логин"
                            />
                            <TextSubtitle style={errorStyle} color="error">
                                {touched.login && errors.login}
                            </TextSubtitle>

                            <AuthLabel icon={<KeyIcon />}>Пароль</AuthLabel>
                            <AuthInput
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                type="password"
                                placeholder="Пароль"
                            />
                            <TextSubtitle color="error" style={errorStyle}>
                                {touched.password && errors.password}
                            </TextSubtitle>

                            <AuthLabel icon={<KeyIcon />}>
                                Повтор пароля
                            </AuthLabel>
                            <AuthInput
                                name="passwordConfirm"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.passwordConfirm}
                                type="password"
                                placeholder="Повтор пароля"
                            />
                            <TextSubtitle color="error" style={errorStyle}>
                                {touched.passwordConfirm &&
                                    errors.passwordConfirm}
                            </TextSubtitle>

                            <AuthLabel>Контакт для связи</AuthLabel>
                            <div className="contact-wrapper">
                                <InputSelect
                                    value={values.contactType}
                                    name="contactType"
                                    placeholder={"Соцсеть"}
                                    onChange={(e) => {
                                        setFieldValue("contactType", e);
                                    }}
                                    options={[
                                        { value: "jabber", label: "Jabber" },
                                        {
                                            value: "telegram",
                                            label: "Telegram",
                                        },
                                    ]}
                                />
                                <AuthInput
                                    name="contactValue"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.contactValue}
                                    type="text"
                                    placeholder="Контакт"
                                />
                            </div>
                            <TextSubtitle color="error" style={errorStyle}>
                                {touched.contactValue && errors.contactValue}
                            </TextSubtitle>

                            <AuthLabel>Код приглашения</AuthLabel>
                            <AuthInput
                                name="inviteCode"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.inviteCode}
                                type="text"
                                placeholder="Код приглашения"
                            />
                            <TextSubtitle color="error" style={errorStyle}>
                                {touched.inviteCode && errors.inviteCode}
                            </TextSubtitle>

                            <div className="auth-buttons-wrapper register">
                                {/*  */}
                                <Checkbox>
                                    Я прочитал и принимаю <br />
                                    <LinkButton
                                        onClick={handleRulesModalOpen}
                                        color="gold"
                                    >
                                        правила сервиса
                                    </LinkButton>
                                </Checkbox>
                                <MainButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    onClick={() => {
                                        console.log(errors);
                                    }}
                                >
                                    Создать
                                </MainButton>
                            </div>
                        </form>
                    )}
                </Formik>
            </AuthWrapper>
            <TextSubtitle
                style={{
                    textAlign: "center",
                    marginTop: "25px",
                    padingBottom: "25px",
                }}
            >
                Есть учётная запись?{" "}
                <AuthLink color="gold" href={"/login" + prevLocation}>
                    Войти
                </AuthLink>
            </TextSubtitle>
        </>
    );
};

export default RegisterPage;
